import Vue from 'vue'
import App from './App.vue'

// 导入路由模块
import router from '@/router/index.js'
import '@/assets/styles/reset.css'

// 引入ElementUI组件库
import ElementUI from 'element-ui';
//引入ElementUI的所有样式
import 'element-ui/lib/theme-chalk/index.css';

//关闭Vue的生产提示
Vue.config.productionTip = false
//使用ElementUI
Vue.use(ElementUI)

new Vue({
  render: h => h(App),
  
  router
}).$mount('#app')
