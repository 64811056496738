<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
body {
  width: 100vw;
  height: 100vh;
}

#app {
  width: 100vw;
  height: 100vh;
}
</style>
