//1.导入vue 和 vuerouter 的包
import Vue from 'vue'
import VueRouter from 'vue-router'

//2.调用vue.use() 函数，把 VueRouter 安装为 Vue 的插件
//vue.use()函数的作用，就是来安装插件的
Vue.use(VueRouter)

//3.公共路由
const constantRoutes = [{
	path: '/',
	name: 'home',
	component: () => import('@/views/home/index.vue'),
	children: []
}, {
	path: '/solution',
	name: 'solution',
	component: () => import('@/views/solution/index.vue'),
	children: []
}, {
	path: '/consult',
	name: 'consult',
	component: () => import('@/views/consult/index.vue'),
	children: []
}, {
	path: '/about',
	name: 'about',
	component: () => import('@/views/about/index.vue'),
	children: []
}, {
	path: '/news',
	name: 'news',
	component: () => import('@/views/news/index.vue'),
	children: []
}, {
	path: '/news/details',
	name: 'newsDetails',
	component: () => import('@/views/news/details/index.vue'),
	children: []
}]

export default new VueRouter({
	mode: 'history', // 去掉url中的#
	scrollBehavior: () => ({
		y: 0
	}),
	routes: constantRoutes
})